export const MagicNumber = {
  zero: 0,
  one: 1,
  three: 3,
  ten: 10,
  fifteen: 15,
  thirty: 30,
  fifty: 50,
  hundred: 100,
  twoHundred: 200,
  fiveHundred: 500,
  threeHundred: 300,
  fourHundredOne: 401,
  thousand:1000,
  twoThousand: 2000,
  nine : 9,
  eleven:11,
  thirtyOne:31,
  eight : 8,
  two: 2,
  sixty: 60,
  fourThirty: 430,
  twelve: 12,
  threeSixtyFive: 365,
  minusTwo: -2, 
  twentyFour: 24
};

