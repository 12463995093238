import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VendorCommissionService } from '../../services/vendor-commission.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-payment',
  templateUrl: './add-payment.component.html'
})
export class AddPaymentComponent implements OnInit {
  public data;
  public vendorId: any;
  public deactiveEvent = new EventEmitter();
  public addPaymentForm: FormGroup;
  vendorCommission: any;
  constructor(
    public activateRoute: ActivatedRoute,
    public vendorCommissionService : VendorCommissionService,
    public modalRef: BsModalRef,
    public formBuilder: FormBuilder
    ) {
      this.vendorCommission = JSON.parse(localStorage.getItem('vendor'));
    this.vendorId = localStorage.getItem('vendorId');
    // if(this.vendorCommission){
      this.addPaymentForm = this.formBuilder.group({
        enterAmount: [null, [Validators.required,Validators.pattern(/^\d+(\.\d{1,2})?$/)]]
      });
    // }
  }

  ngOnInit(): void {
  }

  submitPayment(){
    const payloads = `user_id=${this.vendorId}&amount=${this.addPaymentForm.value.enterAmount}`;
    this.vendorCommissionService.addVendorTransaction(payloads).subscribe((res: any) => {
      if (res && !res.errors) {
        this.deactiveEvent.emit({ type: true});
        this.modalRef.hide();
      }
    });
  }
  get formControls() {
    return this.addPaymentForm.controls;
  }

}
