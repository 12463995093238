import { Component, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { IResponse } from '../../app-interface';
import { AgentManagementService } from '../../services/agent-management.service';
import { InformationManagementService } from '../../services/information-management.service';
import { UserManagementService } from '../../services/user-management.service';
import { VendorCommissionService } from '../../services/vendor-commission.service';
import { UtilityService } from '../../services/utility.service';

@Component({
  selector: 'app-delete-user',
  templateUrl: './delete-user.component.html'
})
export class DeleteUserComponent{
  public deletePaymentForm: FormGroup;
  public data;
  public deleteUser = new EventEmitter();
  public reasonLabel = true;
  constructor(
    public formBuilder: FormBuilder, 
    public userService: UserManagementService, 
    public agentService: AgentManagementService,
    public modalRef: BsModalRef, 
    public toastr: ToastrService, 
    readonly _informationService: InformationManagementService,
    public vendorCommissionService : VendorCommissionService,
    public utilityService: UtilityService
    ) {
  }


  confirmDelete() {
      this.userService.deleteUser(this.data.id).subscribe((res: IResponse) => {
        this.toastr.success(res.message);
        this.deleteUser.emit({ type: true });
        this.modalRef.hide();
      });
  }
}
