import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { IResponse } from '../../common/app-interface';
import { AuthService } from '../../../app/common/services/auth.service';
import { CommonService } from '../../../app/common/services/common.service';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html'
})
export class SigninComponent {
  public signInForm: FormGroup;
  public isShow = false;
  constructor(public modalService: BsModalService, public modalRef: BsModalRef, public formBuilder: FormBuilder,
    public toastr: ToastrService, public commonService: CommonService,
    public router: Router, public authService: AuthService) {
    this.signInForm = this.formBuilder.group({
      emailId: ['', [Validators.required, 
      Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      password: ['', Validators.required]
    });

  }
  
/**
 * Forget Password Functionality
 */
  forgotPassword() {
    localStorage.clear();
    this.router.navigate(['/forgot-password']);
  }

  get emailId() { return this.signInForm.get('emailId'); }
  get password() { return this.signInForm.get('password'); }

  openForgotPassword() {
    this.modalService.show(ForgotPasswordComponent, { class: 'signin-modal' });

  }
  /**
     * API Call for signin
  */
  onSignIn() {
    if (this.signInForm.valid) {
      const payload = {
        email: this.signInForm.value.emailId,
        password: this.signInForm.value.password
      };
      this.authService.onSignIn(payload).subscribe((res: IResponse) => {
        if (res && !res.errors) {
          localStorage.setItem('adminData', JSON.stringify(res.data));
          this.commonService.userData.next(res.data);
          if(res.data['user'].role_slug === 'admin'){
            this.router.navigate(['/user-management']);
            }
            else if(res.data['user'].role_slug === 'vendor'){
              this.router.navigate(['/agent-dashboard']);
            } else{
              this.router.navigate(['/user-management']);
            }
        } else {
          this.toastr.error(res.message);
        }
      });
    } else {
      this.signInForm.markAllAsTouched();
    }
  }
}
