import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { IResponse } from '../../app-interface';
import { AgentManagementService } from '../../services/agent-management.service';
import { UtilityService } from '../../services/utility.service';

@Component({
  selector: 'app-edit-faq',
  templateUrl: './edit-faq.component.html'
})
export class EditFaqComponent implements OnInit {
  public editFaq: FormGroup;
  public showPass = false;
  public data;
  public editEvent = new EventEmitter();
  public reasonLabel = true;

  showCountryCode = true;
  constructor(public formBuilder: FormBuilder, public modalRef: BsModalRef, public agentService: AgentManagementService, public toaster: ToastrService, public utilityService: UtilityService) {
    this.editFaq = this.formBuilder.group({
      question: ['', [Validators.required]],
      answer: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.editFaq.patchValue({
      question: this.data && this.data.question,
      answer: this.data && this.data.answer
    });
  }
  get question() { return this.editFaq.get('question'); }
  get answer() { return this.editFaq.get('answer'); }

  /**
   * API Call for submitting the user profile
  */
  submitFaq() {
    if (this.editFaq.valid) {
      const params = {
        "question": this.editFaq.value.question,
        "answer": this.editFaq.value.answer
      };
      this.utilityService.editFaq(this.data.id, params).subscribe((res: IResponse) => {
        if (res && !res.errors) {
          this.toaster.success(res.message);
          this.editEvent.emit({ res: 200 });
          this.modalRef.hide();
        }
      });
    } else {
      this.editFaq.markAllAsTouched();
    }
  }
}
