import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-guest-user-management',
  templateUrl: './guest-user-management.component.html'
})
export class GuestUserManagementComponent {



}
