import { Component, EventEmitter} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { MagicNumber } from '../../app-magic-string';
import { CommonService } from '../../services/common.service';
import { DashboardAgentService } from '../../services/dashboard-agent.service';
import { ReportsService } from '../../services/reports.service';
@Component({
  selector: 'app-custom-filter',
  templateUrl: './custom-filter.component.html'
})
export class CustomFilterComponent {
  public customEvent = new EventEmitter();
  public data;
  customFilter : FormGroup;
  currentDate = new Date();
  tomorrow = new Date();
  vendorId: any;
  params:any;
  stDate = '&start_date=';
  enDate = '&end_date=';

  constructor(
    public formBuilder: FormBuilder,
    public modalRef: BsModalRef, 
    public dashboardService: DashboardAgentService,
    public toaster: ToastrService,
    public commonService : CommonService,
    public reportService: ReportsService
    ) {
    this.tomorrow.setDate(this.tomorrow.getDate() + MagicNumber.zero );
    this.customFilter = this.formBuilder.group({
      start_date: ['',Validators.required],
      end_date: ['', [Validators.required]],
    });
    const adminData = JSON.parse(localStorage.getItem('adminData'));
    const isAdmin = adminData?.user?.role_slug === 'admin';
    const userId = isAdmin ? localStorage.getItem('viewProfile') : adminData?.user?.id;
    this.vendorId = userId;
  }


  submitFilter(){
    if(this.data){
          this.params = '&type=' + 'custom' + this.stDate + this.getCalulateDate(this.customFilter.value.start_date) + this.enDate +
      this.getCalulateDate(this.customFilter.value.end_date);
          this.customEvent.emit({params:this.params, data:this.data, startDate:this.getCalulateDate(this.customFilter.value.start_date), endDate:this.getCalulateDate(this.customFilter.value.end_date)});
          this.modalRef.hide();
    } else if(!this.vendorId){
      this.params = '&type=' + 'custom' + this.stDate + this.getCalulateDate(this.customFilter.value.start_date) + this.enDate +
      this.getCalulateDate(this.customFilter.value.end_date);
      this.reportService.getAnalyticsData(this.params).subscribe((res: any) => {
        if (res && !res.errors) {
          this.commonService.commissionData.next({status : true , data : res});
          this.modalRef.hide();
        }
      });
    } else{
      this.params = '&vendor_id=' + this.vendorId + this.stDate + this.getCalulateDate(this.customFilter.value.start_date) + this.enDate +
      this.getCalulateDate(this.customFilter.value.end_date);
      this.dashboardService.customSalesFilter(this.params).subscribe((res: any) => {
        if (res && !res.errors) {
          this.commonService.commissionData.next({status : true , data : res.data});
          this.modalRef.hide();
        }
      });
    }
  }
  reset(){
    this.commonService.commissionDataStatus.next({status : true});
  }
  getCalulateDate(date){
    const month = date.getMonth() + MagicNumber.one;
    const formattedMonth = month < MagicNumber.ten ? `0${month}` : month.toString();
    const day = date.getDate();
    const formattedDay = day < MagicNumber.ten ? `0${day}` : day.toString();
    const year = date.getFullYear();
    return `${year}-${formattedMonth}-${formattedDay}`;
  }

}
