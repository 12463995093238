import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { CommonService } from '../../../common/services/common.service';
import { VendorCommissionService } from '../../../../app/common/services/vendor-commission.service';
import { AddPaymentComponent } from '../../../../app/common/modal/add-payment/add-payment.component';
import { VENDOR_DETAILS_COLS } from './vendor-details.component.col.def';
import { VendorDetailsService } from '../../../../app/common/services/vendor-details.service';
@Component({
  selector: 'app-vendor-detail',
  templateUrl: './vendor-detail.component.html',
  styleUrls: ['./vendor-detail.component.scss']
})
export class VendorDetailComponent{
  public vendorId;
  public vendorCommission;
  public colFields = VENDOR_DETAILS_COLS;
  public tableData = {
    tableHeading: 'All Payments', tableType: 'vendorDetails',
    containerClass: 'table-container-sales-detail-vendor', headClass: 'table-head-user', rowClass: 'table-row-user',
  hideSearch: true,
  hideFilter: true,
  vendor_id : null,
  showUnassignQR: true
  };
  cssClass1 ='modal-dialog modal-dialog-centered';

  constructor
  (
    public modalService: BsModalService, 
    public activateRoute: ActivatedRoute, 
    public vendorCommissionService : VendorCommissionService,
    public vendorDetailsService : VendorDetailsService,
    public toastr: ToastrService, 
    public modelref: BsModalRef, 
    public router: Router, 
    public commonService : CommonService,
    readonly location: Location
    ) {
    this.activateRoute.params.subscribe((params) => {
      if (params.id) {
        this.vendorId = params.id;
        localStorage.setItem('vendorId',this.vendorId);
        this.getVendorComission();
      }
    });
    this.tableData.vendor_id = this.vendorId; 
  }


/**
 * API Call for add payment
*/
  addPayment() {
    const value = this.vendorCommission?.total_commission - this.vendorCommission?.commission_paid;
      localStorage.setItem('vendor',JSON.stringify(value));
      const initialState = { data: { userId: this.vendorId, type: 'user',vendorCommission : this.vendorCommission?.total_commission - this.vendorCommission?.commission_paid } };
      this.modelref = this.modalService.show(AddPaymentComponent, { class: 'max-width-605 modal-dialog-centered', initialState });
      this.modelref.content.deactiveEvent.subscribe((res) => {
        if (res.type) {
          this.getVendorComission();
          this.commonService.addPayment.next(true);
        }
      });
  }
  /**
   * API Call for getting user details
  */
  getVendorComission() {
    this.vendorDetailsService.getVenodrData(this.vendorId).subscribe((res: any) => {
      if (res && !res.errors) {
        this.vendorCommission = res.data.data[0];
      }
    });
  }
  /**
   * API Call for getting vendor delete details
  */
  deletePayment(event){
    if(event){
      this.getVendorComission();
    }
  }
}
