import { Component,EventEmitter, OnInit} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { IResponse } from '../../app-interface';
import { UtilityService } from '../../services/utility.service';
import { UserManagementService } from '../../services/user-management.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-show-address-detail',
  templateUrl: './show-address-detail.component.html',
  styleUrls: ['./show-address-detail.component.scss']
})
export class ShowAddressDetailComponent implements OnInit {
  isEditAddressContainerActive: boolean = false;
  states:any;
  cities:any;
  editAddressForm: FormGroup;
  public data;
  public qrCodeData;
  public refreshEvent = new EventEmitter();
  formChanges: boolean = false;

  constructor(public utilityService: UtilityService, public modalRef: BsModalRef, public toastr: ToastrService,private modalService: BsModalService,private formBuilder: FormBuilder,public usermgmtService: UserManagementService) { }

  ngOnInit(): void {
    this.getStates();
    this.getCities(this.data?.qrInfo?.user_stripe_details_state_id)
    this.getQrCode();
    this.editAddressForm = this.formBuilder.group({
      deliveryAddress: [this.data?.qrInfo?.user_stripe_details_address || '', Validators.required],
      zipCode: [
        this.data?.qrInfo?.user_stripe_details_zip_code || '',
        [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(5), Validators.maxLength(10)]
      ],
      selectedState: [this.data?.qrInfo?.user_stripe_details_state_id || '', Validators.required],
      selectedCity: [this.data?.qrInfo?.user_stripe_details_city_id || '', Validators.required]
    });   
    this.editAddressForm.valueChanges.subscribe(() => {
      this.formChanges = true;
    });
  }

  copyMemoryCode(url) {
    const routeUrl = url;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.innerText = routeUrl;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastr.success('QR Memory Code Copied');
  }
  /**
   * Get QR Memory Code
  */
  getQrCode() {
    this.utilityService.getQRCodeSerialNumber(this.data && this.data.qrInfo.serial_number).subscribe((res: IResponse) => {
      this.qrCodeData = res.data;
    });
  }

  openModal() {
    this.isEditAddressContainerActive = true;
  }

  closeModal() {
    this.isEditAddressContainerActive = !this.isEditAddressContainerActive;
  }

  onSubmit(){
    if(this.editAddressForm.valid){
      const params = '&address=' + this.editAddressForm.value.deliveryAddress + '&zip_code=' +
      this.editAddressForm.value.zipCode + '&state_id=' + this.editAddressForm.value.selectedState + '&city_id=' + this.editAddressForm.value.selectedCity + '&contact_id='+ this.data.qrInfo.contact_id;
      this.usermgmtService.updateAddress(params).subscribe((res: IResponse) => {
        if (res && !res.errors) {
          this.toastr.success(res.message);
          this.refreshEvent.emit({ res: 200 });
          this.modalRef.hide();
        }
      });
    } else {
      this.editAddressForm.markAllAsTouched();
    }
    }
    
    getStates() {
      this.utilityService.getStates().pipe(
        catchError(error => {
          console.error('Error fetching states:', error);
          return of([]);
        })
      ).subscribe((res: any) => {
        this.states = res.data;
      });
    }
  
    getCities(stateId: number) {
      this.utilityService.getCities(stateId).pipe(
        catchError(error => {
          console.error('Error fetching cities:', error);
          return of([]);
        })
      ).subscribe((res: any) => {
        this.cities = res.data;
      });
    }

    onStateChange(event: any) {
      const selectedStateId = event.target.value;
      if (selectedStateId) {
        this.getCities(selectedStateId);
        this.editAddressForm.get('selectedCity').setValue('');
        this.editAddressForm.get('selectedCity').updateValueAndValidity();
      } else {
        this.cities = [];
      }
    }

    handleZipCodeInput(event: KeyboardEvent) {
      if (!/^\d$/.test(event.key) && !['Backspace', 'Delete'].includes(event.key)) {
        event.preventDefault();
      }
    }
  }


