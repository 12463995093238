import { Component } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ORDER_MNGMNT_COLS } from './order-management.component.col.def';
import { OrderManagementService } from 'src/app/common/services/order-management.service';

@Component({
  selector: 'app-order-management',
  templateUrl: './order-management.component.html',
  styleUrls: ['./order-management.component.scss']
})
export class OrderManagementComponent {
  public colFields = ORDER_MNGMNT_COLS;
  public tableData = {
    tableHeading: 'Order Management', tableType: 'order', serviceName: 'orderService',
    containerClass: 'table-container-order-mang', headClass: 'table-head-user',
    rowClass: 'table-row-user', bodyClass: 'table-body-user', showOrder: true
  };
  paramsAg: string;
  constructor(public modalService: BsModalService, public bsModalRef: BsModalRef, public orderService: OrderManagementService) { }



  // generateQrCode() {
  //   this.modalService.show(GenerateQrcodeComponent, { class: 'max-width-607 modal-dialog-centered' });
  // }

  /**
   *QR Code Call back
   @param event is action of an event
  */
  // QRCallback(event: Array<number>) {
  //   if (event) {
  //     this.bsModalRef = this.modalService.show(GenerateQrcodeComponent,
  //       {
  //         animated: true, keyboard: true, backdrop: true, ignoreBackdropClick: false,
  //         class: 'max-width-607 modal-dialog-centered'
  //       });
  //     this.bsModalRef.content.onClose.subscribe((result) => {
  //       if (result.close) {
  //         if(result.generateQrCodeForm.agentID){
  //           this.paramsAg = 'number=' + result.generateQrCodeForm.qrCodeNumber + '&vendor_id=' + result.generateQrCodeForm.agentID;
  //          } else{
  //            this.paramsAg = 'number=' + result.generateQrCodeForm.qrCodeNumber;
  //          }
  //         this._qrCodeService.generateQRCode(this.paramsAg).subscribe();
  //       }
  //     });
  //   };
  // }
}
