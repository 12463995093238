<div class="main-right-wrapper">
    <div class="dashboard-container">
        <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item breadcrumb-item-admin">
                    <a (click)="movetoList()">User Management</a>
                </li>
                <li class="breadcrumb-item breadcrumb-item-admin active" aria-current="page">
                    {{ userDetails?.name }}
                </li>
            </ol>
        </nav>
        <div class="user-management-header user-profile-header">
            <div class="user-profile-wrapper">
                <div class="user-details-section">
                    <div class="row user-info-row">
                        <div class="col-4 user-info">
                            <span class="user-info-img">
                                <img [src]="userDetails?.profile_picture || './assets/images/svg/profile-user-logo.svg'"
                                    alt="profile-user-logo" style="width: 100%;" />
                            </span>
                            <div class="user-info-des">
                                <h2 class="user-info-title">{{ userDetails?.name }}</h2>
                                <p class="user-info-email">{{ userDetails?.email }}</p>
                                <p class="user-info-email" *ngIf="userDetails?.account_type === 3">Funeral Home</p>
                                <p class="user-info-email" *ngIf="userDetails?.account_type === 2">Co-Owner</p>
                                <p class="user-info-email" *ngIf="userDetails?.account_type === 1">Owner</p>
                            </div>
                        </div>
                        <!-- <div class="col-3">
                            <div>
                                <p class="user-phone-number">Phone Number</p>
                                <span>{{ userDetails?.contact_number }}</span>
                            </div>
                        </div>
                        <div class="col-3 memory-code-col">
                            <div>
                                <p class="user-memory-code">QR Memory Code</p>
                                <span>{{ userDetails?.serial_number }}</span>
                            </div>
                        </div> -->
                        <!-- <div class="col-2">
                            &nbsp;
                        </div> -->
                    </div>
                    <!-- <div class="row user-info-row">
                        <div class="col-4">
                            &nbsp;
                        </div>
                            <div class="col-3">
                                <p class="user-phone-number">Address</p>
                                <span>
                                    {{ userDetails?.user_stripe_details_address }}
                                    {{ userDetails?.user_stripe_details_state_name ? ', ' + userDetails?.user_stripe_details_state_name : '' }}
                                    {{ userDetails?.user_stripe_details_city_name ? ', ' + userDetails?.user_stripe_details_city_name : '' }}
                                    {{ userDetails?.user_stripe_details_zip_code ? ', ' + userDetails?.user_stripe_details_zip_code : '' }}
                                </span>
                            </div>
                            <div class="col-3">
                                &nbsp;
                            </div>
                            <div class="col-2">
                                &nbsp;
                            </div>
                    </div> -->
                    <div class="box-edit-right box-top-20">
                        <button (click)="showSubOwners()" class="btn btn-outline-primary sales-edit-btn sub-owner-btn"
                            data-bs-toggle="modal" data-bs-target="#editSalesAgentModal">
                            <svg width="14" height="12" viewBox="0 0 14 12" xmlns="http://www.w3.org/2000/svg">
                                <g stroke="#885D40" fill="none" fill-rule="evenodd" stroke-linecap="round"
                                    stroke-linejoin="round">
                                </g>
                            </svg>
                            Sub-Owners</button>
                        <button *ngIf="userDetails?.status == 0" (click)="deactiveAccount()"
                            class="btn button-activate sales-profile-btn"> Activate Account</button>
                        <button *ngIf="userDetails?.status == 1" (click)="deactiveAccount()"
                            class="btn button-deactivate sales-profile-btn"> Deactivate Account</button>
                    </div>
                </div>
                <div class="deactivate-user" *ngIf="userDetails?.status == 0">
                    <p>
                        <span>Deactivated User</span>(Reason: {{ userDetails?.deactivated_reason }})
                    </p>
                </div>
            </div>
        </div>
    </div>

    <!--  add member admin start from here  -->

    <div class="add-member-admin">
        <ng-container *ngIf="userDetails?.member?.length != 0">
            <div>
                <div class="user-management-card" *ngFor="let data of userDetails?.member">
                    <span class="add-member-head">Added Member</span>
                    <div class="add-member-info">
                        <div class="add-member-des">
                            <span class="profile-user-img-wrapper">
                                <img [src]="data?.profile_picture || 'assets/images/svg/user-logo.svg'"
                                    alt="profile-user-img" />
                            </span>
                            <div class="info-profile-des-wrap">
                                <h2>
                                    {{ data?.name }}
                                </h2>
                                <span class="member-name">
                                    <img src="./assets/images/svg/map-pin-icon.svg" alt="map-pin-icon" />
                                    {{ data?.shipping_address?.address }}, {{
                                    data?.shipping_address?.user_stripe_details_city_name }}, {{
                                    data?.shipping_address?.user_stripe_details_state_name }}, US
                                </span>
                                <span class="member-year">{{ data?.dob | date: "yyyy" }}-{{
                                    data?.dod | date: "yyyy"
                                    }}</span>
                            </div>
                        </div>
                        <div class="user-memory-code-wrap">
                            <div class="momory-code-info">
                                <p class="member-code">Memory Code</p>
                                <span class="member-code-data">{{ data?.member_subscription?.serial_number }}</span>
                            </div>
                            <div class="momory-code-info plan-type">
                                <p class="member-year member-code">
                                    Plan Type:
                                </p>
                                <span class="member-code-data">{{ data?.member_subscription?.plan_type == 1 ? 'Basic' :
                                    'Upgraded' }}</span>
                            </div>
                        </div>
                        <div>
                            <button (click)="exportMember(data?.id)"
                                class="btn btn-outline-primary export-data-user">Export
                                GuestBook Data</button>
                        </div>
                    </div>
                    <app-information-details [informationData]="data" [isEdit]="false"></app-information-details>
                </div>
            </div>
        </ng-container>
        <!-- No Member add yet -->
        <div class="no-data-container no-member-add" *ngIf="userDetails?.member?.length == 0">
            <div>
                <img src="./../../../../assets/images/svg/users-no-add.svg" alt="No member add logo">
            </div>
            <p>No Member Added Yet</p>
        </div>

    </div>
</div>
