import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../../common/services/common.service';
import { CustomFilterComponent } from '../../common/modal/custom-filter/custom-filter.component';
import { DashboardAgentService } from '../../common/services/dashboard-agent.service';
import { AGENT_DSHBRD_COLS } from './agent-dashboard.col.def';
import { MagicNumber } from '../../common/app-magic-string';

@Component({
  selector: 'app-agent-dashboard',
  templateUrl: './agent-dashboard.component.html',
  styleUrls: ['./agent-dashboard.component.scss']
})
export class AgentDashboardComponent implements OnInit {

  public colFields = AGENT_DSHBRD_COLS;
  public tableData = {
    tableHeading: 'Dashboard', tableType: 'dashboard',
    serviceName: 'dashboardService', routerLink: '/agent-dashboard',
    containerClass: 'table-container-user-dashboard', headClass: 'table-head-user', rowClass: 'table-row-user',
  //  hideDownloadCsv: true, showAddSales: true, hideFilter: true
  hideSearch: false,
  hideFilter: true
  };
  customFilter : FormGroup;
  dashboardDetails:any;
  commissionDetails:any;
  cssClass1 = 'max-width-605 modal-dialog-centered';
  params: any;
  filterStatus = 'Current Month';
  filterOld = 'Current Month';


  constructor(
    public modalService: BsModalService,
    public formBuilder: FormBuilder, 
    public activateRoute: ActivatedRoute, 
    public dashboardService: DashboardAgentService,
    public toastr: ToastrService, 
    public modelref: BsModalRef, 
    public commonService : CommonService
    ) { }

  ngOnInit(): void {
    this.customFilter = this.formBuilder.group({
      start_date: [new Date().toISOString().slice(MagicNumber.zero,MagicNumber.eight) + '01', [Validators.required]],
      end_date: [new Date().toISOString().slice(MagicNumber.zero,MagicNumber.ten), [Validators.required]],
    });
    this.getCommission();
    this.commonService.commissionData.subscribe((res)=>{
      this.commissionDetails = res?.data;
      res?.status ? this.filterStatus = 'Custom' : '';
    });
    this.commonService.commissionDataStatus.subscribe((res)=>{
      res?.status ? this.filterStatus = this.filterOld : '';
    });
    this.getUserData();
  }

  getCommission(value?){
    const vendorId = JSON.parse(localStorage.getItem('adminData'))?.user?.id;
    this.params = `&vendor_id=${vendorId}&start_date=${this.customFilter.value.start_date}&end_date=${this.customFilter.value.end_date}`;
    const parts = ['&vendor_id=', vendorId];
    if (value !== 'total') {
      parts.push('&start_date=', this.customFilter.value.start_date, '&end_date=', this.customFilter.value.end_date);
    }
    this.params = parts.join('');
    this.dashboardService.customSalesFilter(this.params).subscribe((res: any) => {
      if (res && !res.errors) {
        this.commissionDetails = res?.data;
        this.modelref.hide();
      }
    });
    this.customFilter.get('start_date').valueChanges.subscribe(val => {
      this.customFilter.get('end_date').setValidators(Validators.required);
      this.customFilter.get('end_date').updateValueAndValidity();
    });
  }
 
  getUserData() {
    this.dashboardService.getDashboardUserData().subscribe((res: any) => {
      if (res && !res.errors) {
        this.dashboardDetails = res.data;
      }
    });
  }

  customFilterModal() {
    this.modelref = this.modalService.show(CustomFilterComponent, { class: this.cssClass1 });

  }

  searchStatus(value :string) {
    const selectedValue = value;
    switch (selectedValue) {
      case 'Custom':
        this.filterStatus = 'Custom';
        this.customFilter.patchValue({
          start_date:'',
          end_date: ''
        });
        this.customFilterModal();
        break;
      case 'month':
        this.filterStatus = 'Current Month';
        this.filterOld = 'Current Month';
        this.customFilter.patchValue({
          start_date: new Date().toISOString().slice(MagicNumber.zero,MagicNumber.eight) + '01',
          end_date: new Date().toISOString().slice(MagicNumber.zero,MagicNumber.ten)
        });
        this.getCommission();
        break;
      case 'year':
        this.filterStatus = 'Current Year';
        this.filterOld = 'Current Year';

        this.customFilter.patchValue({
          start_date: this.getCalulateStartYear(),
          end_date: this.getCalulateEndYear()
        });
        this.getCommission();
        break;
      case 'total':
        this.filterStatus = 'Total';
        this.filterOld = 'Total';
        this.getCommission('total');
        break;
      default:
        break;
    }
  }
  

  getCalulateStartYear(){
    const date = new Date();
    date.setMonth(MagicNumber.zero); // set month to December (index 11)
    date.setDate(MagicNumber.one); // set day to 31
    return date.toISOString().substr(MagicNumber.zero, MagicNumber.ten); // extract the first 10 characters (YYYY-MM-DD)
    // return date.getFullYear() + '-' + '01' + '-' + '01';
  }
  getCalulateEndYear() {
    const date = new Date();
    date.setMonth(MagicNumber.eleven); // set month to December (index 11)
    date.setDate(MagicNumber.thirtyOne); // set day to 31
    return date.toISOString().substr(MagicNumber.zero, MagicNumber.ten); // extract the first 10 characters (YYYY-MM-DD)
}
}
