import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, CanAuthLoginGuard } from './common/guards/auth.guard';
import { CmsDetailComponent } from './private/content-management/cms-detail/cms-detail.component';
import { CmsListComponent } from './private/content-management/cms-list/cms-list.component';
import { ContentManagementComponent } from './private/content-management/content-management.component';
import { InformationDetailComponent } from './private/information-management/information-detail/information-detail.component';
import { InformationListComponent } from './private/information-management/information-list/information-list.component';
import { InformationManagementComponent } from './private/information-management/information-management.component';
import { PaymentManagementComponent } from './private/payment-management/payment-management.component';
import { PrivateComponent } from './private/private/private.component';
import { QrcodeManagementComponent } from './private/qrcode-management/qrcode-management.component';
import { UserDetailComponent } from './private/user-management/user-detail/user-detail.component';
import { UserManagementComponent } from './private/user-management/user-management.component';
import { UsersListComponent } from './private/user-management/users-list/users-list.component';
import { ForgotPasswordComponent } from './public/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './public/reset-password/reset-password.component';
import { SigninComponent } from './public/signin/signin.component';
import { AgentManagementComponent } from './private/agent-management/agent-management.component';
import { AgentListComponent } from './private/agent-management/agent-list/agent-list.component';
import { AgentDetailComponent } from './private/agent-management/agent-detail/agent-detail.component';
import { AdManagmentComponent } from './private/ad-managment/ad-managment.component';
import { AgentDashboardComponent } from './private/agent-dashboard/agent-dashboard.component';
import { ReportsComponent } from './private/reports/reports.component';
import { VendorCommissionComponent } from './private/vendor-commission/vendor-commission.component';
import { VendorListComponent } from './private/vendor-commission/vendor-list/vendor-list.component';
import { VendorDetailComponent } from './private/vendor-commission/vendor-detail/vendor-detail.component';
import { FaqsComponent } from './private/faqs/faqs.component';
import { warehouseManagementComponent } from './private/warehouse-management/warehouse-management.component';
import { GuestUserManagementComponent } from './private/guest-user-management/guest-user-management.component';
import { GuestUsersListComponent } from './private/guest-user-management/guest-users-list/guest-users-list.component';
import { OrderManagementComponent } from './private/order-management/order-management.component';

const routes: Routes = [
  { path: '', redirectTo: 'signin', pathMatch: 'full' },
  { path: 'signin', component: SigninComponent, pathMatch: 'full' },
  { path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [CanAuthLoginGuard], pathMatch: 'full' },
  { path: 'reset-password', component: ResetPasswordComponent, pathMatch: 'full', canActivate: [CanAuthLoginGuard]},
  { path: 'agent-dashboard', component: AgentDashboardComponent, pathMatch: 'full' },
  {
    path: '', component: PrivateComponent, canActivate: [AuthGuard], children: [
      {
        path: 'user-management', component: UserManagementComponent, children: [
          { path: '', redirectTo: 'users-list', pathMatch: 'full' },
          { path: 'users-list', component: UsersListComponent, pathMatch: 'full' },
          { path: 'users-detail/:id', component: UserDetailComponent, pathMatch: 'full' },
        ]
      },
      {
        path: 'guest-user-management', component: GuestUserManagementComponent, children: [
          { path: '', redirectTo: 'guest-users-list', pathMatch: 'full' },
          { path: 'guest-users-list', component: GuestUsersListComponent, pathMatch: 'full' }
        ]
      },
      {
        path: 'information-management', component: InformationManagementComponent, children: [
          { path: '', redirectTo: 'information-list', pathMatch: 'full' },
          { path: 'information-list', component: InformationListComponent, pathMatch: 'full' },
          { path: 'information-detail/:id', component: InformationDetailComponent, pathMatch: 'full' },
        ]
      },
      { path: 'agent-management', component: AgentManagementComponent, children: [
        { path: '', redirectTo: 'agent-list', pathMatch: 'full' },
        { path: 'agent-list', component: AgentListComponent, pathMatch: 'full' },
        { path: 'agent-detail/:id', component: AgentDetailComponent, pathMatch: 'full' },
      ]
      },
      {
        path: 'content-management', component: ContentManagementComponent, children: [
          { path: '', redirectTo: 'cms-list', pathMatch: 'full' },
          { path: 'cms-list', component: CmsListComponent, pathMatch: 'full' },
          { path: 'cms-detail/:id', component: CmsDetailComponent, pathMatch: 'full' },
        ]
      },

      { path: 'payment-management', component: PaymentManagementComponent, pathMatch: 'full' },
      { path:'ad-managment', component : AdManagmentComponent , pathMatch:'full' },
      { path: 'qrcode-management', component: QrcodeManagementComponent, pathMatch: 'full' },
      { path: 'warehouse-management', component: warehouseManagementComponent, pathMatch: 'full' },
      { path: 'order-management', component: OrderManagementComponent, pathMatch: 'full' },
      { path: 'reports', component: ReportsComponent, pathMatch: 'full' },

      { path: 'vendor-commission', component: VendorCommissionComponent, children: [
        { path: '', redirectTo: 'vendor-list', pathMatch: 'full' },
        { path: 'vendor-list', component: VendorListComponent, pathMatch: 'full' },
        { path: 'vendor-detail/:id', component: VendorDetailComponent, pathMatch: 'full' },
      ]
      },
      { path:'faqs', component : FaqsComponent, pathMatch:'full' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
