import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Inject, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { DeactiveAccountComponent } from '../../modal/deactive-account/deactive-account.component';
import { ContentManagementService } from '../../services/content-management.service';
import { InformationManagementService } from '../../services/information-management.service';
import { QrcodeManagementService } from '../../services/qrcode-management.service';
import { UserManagementService } from '../../services/user-management.service';
import { AgentManagementService } from '../../services/agent-management.service';
import { DashboardAgentService } from '../../services/dashboard-agent.service';
import { AdManagementService } from '../../services/ad-management.service';
import { FILTER_DATA } from '../../app-const-string';
import { ActivatedRes, FilterData, IResponse } from '../../app-interface';
import { MagicNumber } from '../../app-magic-string';
import { UtilityService } from '../../services/utility.service';
import { ViewQrcodeComponent } from '../../modal/view-qrcode/view-qrcode.component';
import { PaymentManagementService } from '../../services/payment-management.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { GenerateQrcodeComponent } from '../../modal/generate-qrcode/generate-qrcode.component';
import { Router } from '@angular/router';
import { AgentEditProfileComponent } from '../../modal/agent-edit-profile/agent-edit-profile.component';
import { AgentAddProfileComponent } from '../../modal/agent-add-profile/agent-add-profile.component';
import { MarkAsSoldComponent } from '../../modal/mark-as-sold/mark-as-sold.component';
import { MarkAsReturnedComponent } from '../../modal/mark-as-returned/mark-as-returned.component';
import { UnassignQrcodeComponent } from '../../modal/unassign-qrcode/unassign-qrcode.component';
import { ManageQrcodeComponent } from '../../modal/manage-qrcode/manage-qrcode.component';
import { ExportSalesReportComponent } from '../../modal/export-sales-report/export-sales-report.component';
import { CommonService } from '../../services/common.service';
import { VendorCommissionService } from '../../services/vendor-commission.service';
import { ReportsService } from '../../services/reports.service';
import { DeleteReasonComponent } from '../../modal/delete-reason/delete-reason.component';
import { VendorDetailsService } from '../../services/vendor-details.service';
import { ExportSalesVendorComponent } from '../../modal/export-sales-vendor/export-sales-vendor.component';
import { ShowAddressDetailComponent } from '../../modal/address-detail/show-address-detail.component';
import { GuestUserManagementService } from '../../services/guest-user-management.service';
import { WarehouseManagementService } from '../../services/warehouse-management.service';
import { AssignQrAgentComponent } from '../../modal/assign-qr-agent/assign-qr-agent.component';
import { ExportGuestDataComponent } from '../../modal/export-guest-data/export-guest-data.component';
import { ExportWarehouseDataComponent } from '../../modal/export-warehouse-data/export-warehouse-data.component';
import { DeleteUserComponent } from '../../modal/delete-user/delete-user.component';
import { CreateUserPasswordComponent } from 'src/app/private/create-user-password/create-user-password.component';
import { environment } from 'src/environments/environment';
import { OrderManagementService } from '../../services/order-management.service';
import { ChangeShippingStatusComponent } from '../../modal/change-shipping-status/change-shipping-status.component';
import { EditProfileComponent } from '../../modal/edit-profile/edit-profile.component';
import { ExportUserDataComponent } from '../../modal/export-user-data/export-user-data.component';
import { ExportTransactionComponent } from '../../modal/export-transaction/export-transaction.component';
import { ExportInformationComponent } from '../../modal/export-information/export-information.component';
import { ExportOrderComponent } from '../../modal/export-order/export-order.component';
import { ExportQrCodeComponent } from '../../modal/export-qr-code/export-qr-code.component';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})

export class DataTableComponent implements OnInit, OnDestroy,AfterViewInit {
  @Input() colFields;
  @Input() tableData;
  public dataSource = [];
  public filterData = FILTER_DATA;
  public isMasterChecked = false;
  public paginationData = { current_page: null, per_page: null, last_page: null, total: null };
  @Output() generateQREmitter = new EventEmitter();
  @Output() deleteVendorPayment = new EventEmitter();
  @Output() deleteUser = new EventEmitter();
  @Output() createPassword = new EventEmitter();
  public onGenerateQRIdList = [];
  public unassignQrVendor = [];
  private defaultSerachKey = false;
  public qrcodeData;
  public paginationArr = ['pre'];
  public searchData: string | null = null;
  public loader: NgxSpinnerService;
  public  readonly router: Router;
  public paymentService: PaymentManagementService;
  public qrCodeServie: QrcodeManagementService;
  public cmsService: ContentManagementService;
  public informationService: InformationManagementService;
  public userService: UserManagementService;
  public guestUserService: GuestUserManagementService;
  public warehouseService: WarehouseManagementService;
  public orderManagementService: OrderManagementService;
  public agentService: AgentManagementService;
  public dashboardService: DashboardAgentService;
  public adService: AdManagementService;
  public vendorCommissionService : VendorCommissionService;
  public vendorDetailsService : VendorDetailsService;

  agentId: number;
  agentDetails: any;
  paramsAg: string;
  paramsReturn: string;
  showAction = false;
  cssClass1 = 'max-width-605 modal-dialog-centered';
  cssClass2 = 'max-width-607 modal-dialog-centered';
  markAsSoldCSS = 'dashboard-sales-modal modal-dialog-centered';
  markAsSoldAssign = 'dashboard-sales-modal modal-dialog-centered';
  masterQR = false;
  masterAgent = false;
  memorialUrl: string;
  constructor(public modalService: BsModalService, @Inject(DOCUMENT) private readonly document: Document, public toastr: ToastrService,
    public utilityService: UtilityService, public commonService : CommonService,public reportService: ReportsService,
    public modelref: BsModalRef, public injector: Injector) {
    this.loader = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.router = injector.get<Router>(Router);
    this.paymentService = injector.get<PaymentManagementService>(PaymentManagementService);
    this.vendorCommissionService = injector.get<VendorCommissionService>(VendorCommissionService);
    this.vendorDetailsService = injector.get<VendorDetailsService>(VendorDetailsService);
    this.qrCodeServie = injector.get<QrcodeManagementService>(QrcodeManagementService);
    this.cmsService = injector.get<ContentManagementService>(ContentManagementService);
    this.informationService = injector.get<InformationManagementService>(InformationManagementService);
    this.userService = injector.get<UserManagementService>(UserManagementService);
    this.guestUserService = injector.get<GuestUserManagementService>(GuestUserManagementService);
    this.warehouseService = injector.get<WarehouseManagementService>(WarehouseManagementService);
    this.orderManagementService = injector.get<OrderManagementService>(OrderManagementService);
    this.agentService = injector.get<AgentManagementService>(AgentManagementService);
    this.dashboardService = injector.get<DashboardAgentService>(DashboardAgentService);
    this.adService = injector.get<AdManagementService>(AdManagementService);
    this.reportService = injector.get<ReportsService>(ReportsService);
  }

  ngOnInit(): void {
    this.filterData.vendor_id = this.tableData.vendor_id || null;
    this.showAction = false;
    this.getDataToTable(true);
    this.memorialUrl = environment.memorialUrl;
  }
  ngAfterViewInit(): void {
    this.commonService.addPayment.subscribe((res=>{
       this.getDataToTable(false) ;
    }));
  }


  ngOnDestroy(): void {
    this.orderManagementService.resetParams(this.filterData);
    this.qrCodeServie.resetParams(this.filterData);
  }


  /**
   * API Call for Management List
  */
  getDataToTable(validFilter: boolean) {
    const serviceName = this.getRequestedTypeManagement();
    const data = this.getFilterData();
    this.dataSource = this.filterData.download_csv === null && validFilter ? [] : this.dataSource;
    serviceName.getData(this.tableData.tableType !== 'vendorDetails' ? data.join('&') : this.filterData.vendor_id).subscribe((res) => {
      if (res && !res.errors && res.data) {
        if (this.filterData.download_csv !== null) {
          this.document.location.href = res.data;
          this.filterData.download_csv = null;
        } else {
          const managementData = this.tableData.tableType === 'cms' ? res.data : res.data.data;
          this.dataSource = this.tableData && this.tableData.tableType === 'qrCode' ? this._isCheckedData(managementData) : managementData;
          this.paginationData = res.data;
          this.filterData.page = MagicNumber.one;
          this.showAction = false;
          if(this.tableData && this.tableData.tableType === 'qrCode'){
            for(let i=0; i<this.dataSource.length; i++){
              if(this.dataSource[i].sold_on || this.dataSource[i].vendor_name){
                this.masterQR = true;
                break;
              } else{
                this.masterQR = false;
              }
            }
          }
          if(this.tableData && this.tableData.tableType === 'agdetail'){
            for(let i=0; i<this.dataSource.length; i++){
              if(this.dataSource[i].sold_on){
                this.masterAgent = true;
                break;
              } else{
                this.masterAgent = false;
              }
            }
          }
        }
      }
    });
  }

  /**
   * add list to isChecked param
   * @param data is response of list
  */
  private _isCheckedData(data) {
    return data?.map((v) => ({ ...v, isChecked: false }));
  }

  /**
   * Deactive selected Item
   * @param data is response of list
  */
  deactiveAccount(data) {
    if (data.status === MagicNumber.one) {
      const initialState = { data: { userId: data.id, type: this.tableData && this.tableData.tableType } };
      this.modelref = this.modalService.show(DeactiveAccountComponent, { class: this.cssClass1, initialState });
      this.modelref.content.deactiveEvent.subscribe((res) => {
        if (res) {
          this.getDataToTable(false);
        }
      });
    } else {
      this.activateAccount(data);
    }
  }

    deleteAccount(data) {
      const initialState = { data: { id: data.id } };
      this.modelref = this.modalService.show(DeleteUserComponent, { class: 'max-width-526 modal-dialog-centered delete-faq-modal', initialState });
      this.modelref.content.deleteUser.subscribe((res) => {
        if (res.type) {
          this.deleteUser.emit(true);
          this.getDataToTable(false);
        }
      });
  }

  createUserPassword(data) {
    const initialState = { data: { id: data.id, email: data.email } };
    this.modelref = this.modalService.show(CreateUserPasswordComponent, { class: 'max-width-605 modal-dialog-centered delete-faq-modal create-passwored-admin', initialState });
    this.modelref.content.createPassword.subscribe((res) => {
      if (res.type) {
        this.createPassword.emit(true);
      }
    });
}

  /**
   * Activate selected Item
   * @param data is response of list
  */
  activateAccount(data) {
    const payload = {
      is_deactivate: MagicNumber.one,
      userId: data.id
    };
    const setService = this.tableData && (this.tableData.tableType === 'user'|| this.tableData.tableType === 'agent') ? this.userService : this.informationService;
    setService.deactiveAccount(payload).subscribe((res: IResponse) => {
      this.toastr.success(res.message);
      this.getDataToTable(false);
    });
  }

  /**
   * Serach list
  */
  searchTable() {
    this.filterData.page = null;
    this.defaultSerachKey = false;
    this.onGenerateQRIdList = [];
    this.checkUncheckAll(false);
    this.getDataToTable(true);
  }

  searchGenericStatus() {
    this.filterData.search = this.searchData;
    this.commonService.searchData.next(this.filterData);
    this.searchTable();
  }



  /**
   * get Filtered data
  */
  getFilterData() {
    const data = [];
    for (const key in this.filterData) {
      if (this.filterData[key as keyof FilterData] === '') {
        this.filterData[key] = null;
      }
      if (this.filterData[key as keyof FilterData] !== null) {
        data.push(`${key}=${this.filterData[key as keyof FilterData]}`);
      }
    }
    return data;
  }


  /**
   * on page Change
   * @param page is current page
  */
  pageChange(event) {
    this.filterData.page = event.page;
    this.searchTable();
  }
  pageChanged(event) {
    this.filterData.page = event;
    this.getDataToTable(false);
  }

  viewProfile(id){
    localStorage.setItem('viewProfile',id);
  }

    /**
   * API Call for deleteing the payment
  */
    deletePayment(data,column) {
      const initialState = { data: { id: data.id } };
      this.modelref = this.modalService.show(DeleteReasonComponent, { class: 'max-width-605 modal-dialog-centered', initialState });
      this.modelref.content.deactiveEvent.subscribe((res) => {
        if (res.type) {
          this.deleteVendorPayment.emit(true);
          this.getDataToTable(false);
        }
      });
  }




  /**
   * Sort order by Column
   * @param column is selected one
  */
  sortTable(column, sort) {
    if(column.fieldType == "DEC_TEXT_GUEST" && column.headerName == "Deceased Member"){
      this.filterData.order_by == 'deceased member';
    } else{
      this.filterData.order_by = this.tableData && this.tableData.tableType === 'qrCode' ? column.orderby : column.fieldName;
    }
    this.filterData.order = sort;
    this.searchTable();
  }

  /**
   * get requested Management Service
  */
  getRequestedTypeManagement() {
    let serviceName = null;
    if (this.tableData && this.tableData.tableType === 'user') {
      serviceName = this.userService;
    } else if (this.tableData && this.tableData.tableType === 'guest-user') {
      serviceName = this.guestUserService;
    } else if (this.tableData && this.tableData.tableType === 'warehouse') {
      serviceName = this.warehouseService;
    } else if (this.tableData && this.tableData.tableType === 'order') {
      serviceName = this.orderManagementService;
    } else if (this.tableData && this.tableData.tableType === 'cms') {
      serviceName = this.cmsService;
    } else if (this.tableData && this.tableData.tableType === 'qrCode') {
      serviceName = this.qrCodeServie;
    } else if (this.tableData && this.tableData.tableType === 'payment') {
      serviceName = this.paymentService;
    } else if (this.tableData && this.tableData.tableType === 'agent') {
      serviceName = this.agentService;
    } else if (this.tableData && this.tableData.tableType === 'dashboard') {
      serviceName = this.dashboardService;
    } else if (this.tableData && this.tableData.tableType === 'agdetail') {
      serviceName = this.dashboardService;
    }else if (this.tableData && this.tableData.tableType === 'vendorCommission') {
      serviceName = this.vendorCommissionService;
    }else if (this.tableData && this.tableData.tableType === 'vendorDetails') {
      serviceName = this.vendorDetailsService;
    }    else if (this.tableData && this.tableData.tableType === 'Advertisement') {
      serviceName = this.adService;
    }else if (this.tableData && this.tableData.tableType === 'reports') {
      serviceName = this.reportService;
    } else {
      serviceName = this.informationService;
    }
    return serviceName;
  }

  /**
   * Selcted Dropdown Status
   * @param event is an action
  */
  searchStatus(event) {
    this.filterData.status = event.target.value === 'null' ? null : Number(event.target.value);
    this.commonService.searchData.next(this.filterData);
    this.searchTable();
  }

  searchUserState(event){
    this.filterData.payment_mode = event.target.value === 'null' ? null : Number(event.target.value);
    this.searchTable();
  }

  /**
   * Selcted Dropdown Duration
   * @param event is an action
  */
  searchDuration(event) {
    this.filterData.duration = event.target.value === 'null' ? null : Number(event.target.value);
    this.searchTable();
  }
  searchShipping(event) {
    this.filterData.admin_shipping_status = event.target.value === 'null' ? null : (event.target.value);
    this.searchTable();
  }
  searchType(event) {
    this.filterData.account_type = event.target.value === 'null' ? null : (event.target.value);
    this.searchTable();
  }

  /**
   * Check and Uncheck list
   * @param isSelectedAll is an boolean
  */
  checkUncheckAll(isSelectedAll: boolean) {
    for (let i = MagicNumber.zero; i < this.dataSource?.length; i++) {
      this.dataSource[i].isChecked = isSelectedAll;
    }
    if(this.dataSource?.length>MagicNumber.one){
      this.showAction = true;
    } else{
      this.showAction = false;
    }
    this.fetchCheckedIDs(this.dataSource);
  }

  checkUncheckAllQR(isSelectedAll: boolean){
    for (let i = MagicNumber.zero; i < this.dataSource?.length; i++) {
      this.dataSource[i].isChecked = isSelectedAll;
    }
    this.fetchCheckedIDsQR(this.dataSource);
  }
  /**
   * Check and Uncheck selected Item
   * @param list is an selected row
  */
  fetchCheckedIDs(list) {
    this.onGenerateQRIdList = [];
    list?.forEach((value) => {
      if (value.isChecked) {
        this.onGenerateQRIdList?.push(value.id);
      }
    });
    if (this.onGenerateQRIdList?.length === MagicNumber.zero) {
      this.isMasterChecked = false;
    }
    if (this.onGenerateQRIdList?.length > MagicNumber.one) {
      this.showAction = true;
    } else{
      this.showAction = false;
    } if (list?.length > MagicNumber.zero && list?.length === this.onGenerateQRIdList?.length) {
      this.isMasterChecked = true;
    } else {
      this.isMasterChecked = false;
    }
  }

  fetchCheckedIDsQR(list){
    this.unassignQrVendor = [];
    list?.forEach((value) => {
      if (value.isChecked) {
        this.unassignQrVendor?.push((value?.id).toString());
      }
    });
    if (this.unassignQrVendor?.length === MagicNumber.zero) {
      this.isMasterChecked = false;
    }
    if (list?.length > MagicNumber.zero && list.length === this.unassignQrVendor?.length) {
      this.isMasterChecked = true;
    } else {
      this.isMasterChecked = false;
    }
  }
  /**
 * Reset search by client list
 * @param event search input field
*/
  // keyFunc(event) {
  //   if (event.target.value.trim().length !== 0 || event.target.value.length === 0) {
  //     this.filterData.search = this.searchData;
  //     this.filterData.page = MagicNumber.one;
  //     this.defaultSerachKey = true;
  //     this.searchTable();
  //   }
  // }


  keyFunc(event) {
    if (event.target.value === '' && !this.defaultSerachKey) {
      this.filterData.search = this.searchData;
      this.filterData.page = MagicNumber.one;
      this.defaultSerachKey = true;
      this.searchTable();
    }
  }
  /**
   * generate QR code
  */
  generateQrCode() {
    this.modelref = this.modalService.show(GenerateQrcodeComponent,
      { animated: true, keyboard: true, backdrop: true, ignoreBackdropClick: false, class: this.cssClass2 });
    this.modelref.content.onClose.subscribe((result) => {
      if (result.close) {
        if(result.generateQrCodeForm.rememberAssign == 1){
          this.paramsAg = 'number=' + result.generateQrCodeForm.qrCodeNumber + '&remember_me=' + result.generateQrCodeForm.rememberAssign;
        } else if(result.generateQrCodeForm.agentID){
         this.paramsAg = 'number=' + result.generateQrCodeForm.qrCodeNumber + '&vendor_id=' + result.generateQrCodeForm.agentID;
        } else{
          this.paramsAg = 'number=' + result.generateQrCodeForm.qrCodeNumber;
        }
        this.qrCodeServie.generateQRCode(this.paramsAg).subscribe((res: any) => {
          this.paramsAg = null;
          this.dataSource = res.data.data;
          this.paginationData = res.data;
          this.toastr.success(res.message);
          this.getDataToTable(true);
        this.checkUncheckAllQR(false);
        });
      }
    });
  }
  changeShippingStatus(data){
    const initialState = { data: data};
    this.modelref = this.modalService.show(ChangeShippingStatusComponent,
      { animated: true, keyboard: true, backdrop: true, ignoreBackdropClick: false, class: this.cssClass2, initialState });
      this.modelref.content.manageShipping.subscribe((res) => {
        if (res) {
          this.getDataToTable(true);
        }
      });
  }

  manageQRCode(){
    const initialState = { data: this.onGenerateQRIdList };

    this.modelref = this.modalService.show(ManageQrcodeComponent,
      { animated: true, keyboard: true, backdrop: true, ignoreBackdropClick: false, class: this.cssClass2,initialState });

      this.modelref.content.manageEvent.subscribe((res) => {
        if (res) {
          this.getDataToTable(true);
          this.checkUncheckAllQR(false);
        }
      });
  }

  assignQRCodeAgent(){
    const initialState = { data: this.onGenerateQRIdList };

    this.modelref = this.modalService.show(AssignQrAgentComponent,
      { animated: true, keyboard: true, backdrop: true, ignoreBackdropClick: false, class: this.cssClass2,initialState });

      this.modelref.content.manageEvent.subscribe((res) => {
        if (res) {
          this.getDataToTable(true);
          this.checkUncheckAllQR(false);
          window.location.reload();
        }
      });
  }

  assignQR(list: Array<number>,id?) {
    const initialState = {data:list};
    this.modelref = this.modalService.show(UnassignQrcodeComponent, { class: this.cssClass1, initialState });
    this.modelref.content.unassignEvent.subscribe((res) => {
      if (res) {
        this.getDataToTable(true);
        this.checkUncheckAllQR(false);
      }
    });
}

  /**
   * download QR Code
  */

  unassignQrCode(id){
    this._unassignQR(this.unassignQrVendor,id);
  }

  // private _unassignQR(list: Array<number>,id) {
  //   this.agentService.unassignQrCod(list).subscribe((res: ActivatedRes) => {
  //     if (res) {
  //       this.toastr.success(res.message);
  //       // this.document.location.href = res.data;
  //       this.getDataToTable(true);
  //       this.checkUncheckAllQR(false);
  //     }
  //   });
  // }

  _unassignQR(list: Array<number>,id?) {
    const initialState = {data:list};
    this.modelref = this.modalService.show(UnassignQrcodeComponent, { class: this.markAsSoldAssign, initialState });
    this.modelref.content.unassignEvent.subscribe((res) => {
      if (res) {
        this.getDataToTable(true);
        this.checkUncheckAllQR(false);
        window.location.reload();
      }
    });
}



  downloadQRCode() {
    this._apiCallQRCode(this.onGenerateQRIdList);
  }


  private _apiCallQRCode(list: Array<number>) {
    this.qrCodeServie.downloadAllQRCode(list).subscribe((res: ActivatedRes) => {
      if (res) {
        this.document.location.href = res.data;
        this.checkUncheckAll(false);
      }
    });
  }

  downloadSingleQrCode(serialNumber: string) {
    this.utilityService.getQRCodeSerialNumber(serialNumber).subscribe((res: IResponse) => {
      if (res) {
        this.qrcodeData = res.data;
        this.utilityService.downloadCode(this.qrcodeData.url);
        this.checkUncheckAll(false);
      }
    });
  }



  /**
   * Open modal of QRCode
   * @param code selected Item
  */
  showQrCode(code) {
    const initialState = { data: { qrInfo: code } };
    this.modalService.show(ViewQrcodeComponent, { class: this.cssClass2, initialState });
  }

  showDeliveryAddress(code) {
    const initialState = { data: { qrInfo: code } };
    this.modelref = this.modalService.show(ShowAddressDetailComponent, { class: this.cssClass2, initialState });
    this.modelref.content.refreshEvent.subscribe((res) => {
      if (res) {
        this.getDataToTable(true);
        // window.location.reload();
      }
    });
  }


  moveToDetail(data) {
    if (this.tableData && this.tableData.tableType === 'user' && data) {
      this.router.navigate(['/user-management/users-detail/', data.id]);
    } else if (this.tableData && this.tableData.tableType === 'information' && data) {
      this.router.navigate(['/information-management/information-detail/', data.id]);
    }
  }

  getInnerText(el) {
    return el.innerText;
  }


  addProfile() {
    this.modelref = this.modalService.show(AgentAddProfileComponent, { class: this.cssClass1 });
    this.modelref.content.addEvent.subscribe((res) => {
      if (res) {
      this.getDataToTable(false);
      }
    });
  }
  exportSalesReport() {
    this.modelref = this.modalService.show(ExportSalesReportComponent, { class: this.cssClass1 });

  }

  exportGuestData(){
    this.modelref = this.modalService.show(ExportGuestDataComponent, { class: this.cssClass1 });
  }

  exportWarehouseData(){
    this.modelref = this.modalService.show(ExportWarehouseDataComponent, { class: this.cssClass1 });
  }

  exportUserData(){
    this.modelref = this.modalService.show(ExportUserDataComponent, { class: this.cssClass1 });
  }

  exportTransactionData(){
    this.modelref = this.modalService.show(ExportTransactionComponent, { class: this.cssClass1 });
  }

  exportInformationData(){
    this.modelref = this.modalService.show(ExportInformationComponent, { class: this.cssClass1 });
  }

  exportOrderData(){
    this.modelref = this.modalService.show(ExportOrderComponent, { class: this.cssClass1 });
  }

  exportQrCodeData(){
    this.modelref = this.modalService.show(ExportQrCodeComponent, { class: this.cssClass1 });
  }

  exportSalesReportVendor() {
    const initialState = { data: { status:'vendor' }};
    localStorage.setItem('vendorDetails',JSON.stringify(initialState));
    this.modelref = this.modalService.show(ExportSalesVendorComponent, { class: this.cssClass1});

  }

  editProfile(data) {
      const initialState = { data: { id: data.id, name:data.name, email:data.email, password:data.password, mobile:data.mobile,
        address:data.address,commission: data.commission, type: this.tableData && this.tableData.tableType } };
      this.modelref = this.modalService.show(AgentEditProfileComponent, { class: this.cssClass1, initialState });
      this.modelref.content.editEvent.subscribe((res) => {
        if (res) {
          this.getDataToTable(false);
        }
      });
  }

  editProfileUser(data){
    const initialState = { data: { id: data.id, name:data.name, email:data.email, contact_number:data.contact_number, contact_id: data.contact_id} };
    this.modelref = this.modalService.show(EditProfileComponent, { class: this.cssClass1, initialState });
    this.modelref.content.editEvent.subscribe((res) => {
      if (res) {
        this.getDataToTable(false);
      }
    });
  }

  markAsSold(data,crdate?) {
    const initialState = { data: { id: data.id } };
    this.modelref = this.modalService.show(MarkAsSoldComponent, { class: this.markAsSoldCSS, initialState });
    this.modelref.content.soldEvent.subscribe((res) => {
      if (res) {
        if(!crdate){
        window.location.reload();
        this.getDataToTable(false);
        } else{
          this.getDataToTable(true);
        }
      }
    });
}

// markAsReturned(data,crdate?) {
//   const initialState = { data: { id: data.id } };
//   this.modelref = this.modalService.show(MarkAsReturnedComponent, { class: this.markAsSoldCSS, initialState });
//   this.modelref.content.soldEvent.subscribe((res) => {
//     if (res) {
//       if(!crdate){
//       window.location.reload();
//       this.getDataToTable(false);
//       } else{
//         this.getDataToTable(true);
//       }
//     }
//   });
// }

markAsReturned(data) {
  const initialState = { data: { data } };
  this.modelref = this.modalService.show(MarkAsReturnedComponent,
    { animated: true, keyboard: true, backdrop: true, ignoreBackdropClick: false,initialState, class: this.cssClass2 });
  this.modelref.content.onClose.subscribe((result) => {
    if (result.close) {
      if(result.markAsReturnedCodeForm.retReasonComment === ''){
        this.paramsReturn = data.id + '?&return_reason=' + result.markAsReturnedCodeForm.retReasonId;
      } else{
        this.paramsReturn = data.id + '?&return_reason=' + result.markAsReturnedCodeForm.retReasonId + '&return_reason_other=' + result.markAsReturnedCodeForm.retReasonComment;;
      }
      this.dashboardService.markAsReturn(this.paramsReturn).subscribe((res: any) => {
        this.paramsReturn = null;
        this.toastr.success(res.message);
        this.getDataToTable(true);
        window.location.reload();
      });
    }
  });
}

}
